import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ROUTER_CONSTANTS } from './shared/utils/router.constants';

const routes: Routes = [
  {
    path: 't',
    loadChildren: () => import('./test-renderer/test-renderer.module').then((m) => m.TestRendererModule),
  },
  {
    path: ROUTER_CONSTANTS.PUBLIC.CREATE_ORGANIZATION_FORM.PATH,
    loadChildren: () =>
      import('./create-organization-form/create-organization-form.module').then((m) => m.CreateOrganizationFormModule),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
